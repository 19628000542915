// Login.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import logo from '../assets/logov2.webp';
import '../style.css';
import '../fonts/linearicons/style.css';
import front from '../assets/front.png';
import back from '../assets/back.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [confPassword, setConfPassword] = useState('');
  const [msg, setMsg] = useState(''); // Added line
  const history = useHistory();
  const [showRegister, setShowRegister] = useState(false);

  const Auth = async (e) => {
    e.preventDefault();
    try {
      if (showRegister) {
        await axios.post('https://api.katalismedia.com/users', {
          name: name,
          email: email,
          password: password,
          confPassword: confPassword,
        });
        history.push('/');
      } else {
        await axios.post('https://api.katalismedia.com/login', {
          email: email,
          password: password,
        });
        history.push('/dashboard');
      }
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg.replace(/</g, '&lt;').replace(/>/g, '&gt;'));
      }
    }
  };

  const handleRegister = () => {
    setShowRegister(true);
  };

  const handleLogin = () => {
    setShowRegister(false);
  };

  return (
    <div className="wrapper">
      <div className="inner">
        <img src={front} alt="front" className="image-1" />
        <form onSubmit={Auth}>
          <img src={logo} alt="logo" className="logo" />
          <h5>{showRegister ? 'Buat Akun ?' : 'Login'}</h5>
          {showRegister && (
            <div className="form-holder">
              <span className="lnr lnr-user"></span>
              <input
                type="text"
                className="form-control"
                placeholder="Nama"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          )}
          <div className="form-holder">
            <span className="lnr lnr-envelope"></span>
            <input
              type="text"
              className="form-control"
              placeholder="Mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-holder">
            <span className="lnr lnr-lock"></span>
            <div className="password-input-container">
              <input
                className="form-control"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          {showRegister && (
            <div className="form-holder">
              <span className="lnr lnr-lock"></span>
              <div className="password-input-container">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Confirm Password"
                  value={confPassword}
                  onChange={(e) => setConfPassword(e.target.value)}
                />
              </div>
            </div>
          )}
          <button type="submit">
            <span>{showRegister ? 'Register' : 'Login'}</span>
          </button>
          <div className="field mt-5">
                  {/* Menampilkan pesan kesalahan dengan aman */}
                  <p className="has-text-centered danger" dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
          {/* New Button */}
          <button
            onClick={showRegister ? handleLogin : handleRegister}
            className="button is-primary is-fullwidth"
          >
            {showRegister ? 'Login' : 'Register'}
          </button>
        </form>
        <img src={back} alt="Logo" className="image-2" />
      </div>
    </div>
  );
};

export default Login;
