export const arrayItems = [
  {
    name: "IDCA",
    id: "IDCA",
    description: "Identify, Describe, Convince, Action",
    option: {
      model: "text-davinci-003",
      temperature: 0,
      max_tokens: 2000,
      top_p: 1,
      frequency_penalty: 0.5,
      presence_penalty: 0,
    },
  },
  {
    name: "CUBA",
    id: "CUBA",
    description: "Curiosity, Uniqueness, Believability, Action",
    option: {
      model: "text-davinci-003",
      temperature: 0,
      max_tokens: 2000,
      top_p: 1,
      frequency_penalty: 0.5,
      presence_penalty: 0,
    },
  },
  {
    name: "HOOK",
    id: "HOOK",
    description: "Highlight, Offer, Overcome, Keep",
    option: {
      model: "text-davinci-003",
      temperature: 0,
      max_tokens: 2000,
      top_p: 1,
      frequency_penalty: 0.5,
      presence_penalty: 0,
    },
  },
  {
    name: "CAP",
    id: "CAP",
    description: "Curiosity, Action, Persuasion",
    option: {
      model: "text-davinci-003",
      temperature: 0,
      max_tokens: 2000,
      top_p: 1,
      frequency_penalty: 0.5,
      presence_penalty: 0,
    },
  },
  {
    name: "PPT",
    id: "PPT",
    description: "Picture, Promise, Testimonials",
    option: {
      model: "text-davinci-003",
      temperature: 0,
      max_tokens: 2000,
      top_p: 1,
      frequency_penalty: 0.5,
      presence_penalty: 0,
    },
  },
  {
    name: "APP",
    id: "APP",
    description: "Audience, Promise, Proof",
    option: {
      model: "text-davinci-003",
      temperature: 0,
      max_tokens: 2000,
      top_p: 1,
      frequency_penalty: 0.5,
      presence_penalty: 0,
    },
  },
  {
    name: "RISE",
    id: "RISE",
    description: "Relate, Intrigue, Solve, Encourage",
    option: {
      model: "text-davinci-003",
      temperature: 0,
      max_tokens: 2000,
      top_p: 1,
      frequency_penalty: 0.5,
      presence_penalty: 0,
    },
  },
  {
    name: "FAPC",
    id: "FAPC",
    description: "Feature, Advantage, Proofs, Close",
    option: {
      model: "text-davinci-003",
      temperature: 0,
      max_tokens: 2000,
      top_p: 1,
      frequency_penalty: 0.5,
      presence_penalty: 0,
    },
  },
  {
    name: "MUSE",
    id: "MUSE",
    description: "Motivation, Understand, Stimulate",
    option: {
      model: "text-davinci-003",
      temperature: 0,
      max_tokens: 2000,
      top_p: 1,
      frequency_penalty: 0.5,
      presence_penalty: 0,
    },
  },
  {
    name: "PAS",
    id: "PAS",
    description: "Problem, Agitate, Solution",
    option: {
      model: "text-davinci-003",
      temperature: 0,
      max_tokens: 2000,
      top_p: 1,
      frequency_penalty: 0.5,
      presence_penalty: 0,
    },
  },
];
