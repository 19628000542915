import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useHistory } from 'react-router-dom';
import "../App.css";
import { Configuration, OpenAIApi } from "openai";
import OptionSelection from "../components/OptionSelection";
import Translation from "../components/Translation";
import { arrayItems } from "../AIOptions";


const Dashboard = () => {
  const [name, setName] = useState('');
  const [token, setToken] = useState('');
  const [expire, setExpire] = useState('');
  const [users, setUsers] = useState([]);
  const history = useHistory();

  const Logout = async () => {
    try {
        await axios.delete('https://api.katalismedia.com/logout');
        history.push("/");
    } catch (error) {
        console.log(error);
    }
}

  useEffect(() => {
    refreshToken();
    getUsers();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get('https://api.katalismedia.com/token');
      setToken(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        history.push("/");
      }
    }
  }

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date();
    if (expire * 1000 < currentDate.getTime()) {
      const response = await axios.get('https://api.katalismedia.com/token');
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setToken(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  const getUsers = async () => {
    const response = await axiosJWT.get('https://api.katalismedia.com/users', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    setUsers(response.data);
    history.push('/dashboard');
  }

  const configuration = new Configuration({
    apiKey: "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",
  });
  const openai = new OpenAIApi(configuration);
  const [option, setOption] = useState({});
  const [result, setResult] = useState("");
  const [input, setInput] = useState("");

  const selectOption = (option) => {
    setOption(option);
  };

  const doStuff = async () => {
    let object = { ...option, prompt: input };
    try {
      const response = await openai.createCompletion(object);
      setResult(response.data.choices[0].text);
    } catch (error) {
      console.error("Kesalahan saat melakukan permintaan OpenAI:", error);

      // Menampilkan pemberitahuan alert
      alert("Terjadi kesalahan saat melakukan permintaan OpenAI. Mohon coba lagi nanti.");
    }
  };


  return (
    <div className="container mt-5">
      <h1 className="nama">Welcome Back: {name}</h1>
      {Object.values(option).length === 0 ? (
        <OptionSelection arrayItems={arrayItems} selectOption={selectOption} />
      ) : (
        <Translation doStuff={doStuff} setInput={setInput} result={result} />
      )}
                           <div className="buttons">
                                <button onClick={Logout} className="button is-light">
                                    Log Out
                                </button>
                                <button onClick={getUsers} className="button is-info">Back</button>
                            </div>
    </div>
  );
}

export default Dashboard;
